<script setup lang="ts">
import { ref } from "vue";
import { FormKit } from "@formkit/vue";
import { useRouter } from "vue-router";
import { RESET_PASS_REDIRECT_URL } from "@/supabase/init";
import { useAuthStore } from "@/stores/auth";
import { useApiWrapper } from "@/composables/useApiWrapper";
import { FwbModal, FwbButton, FwbSpinner, FwbAlert } from "flowbite-vue";
import { supabase } from "@/supabase/init";
import { onMounted } from "vue";
import { computed } from "vue";
import { event } from 'vue-gtag';
import i18n from "@/i18n";
import { useI18n } from "vue-i18n";

const { status: signInStatus, execute: executeLogin } = useApiWrapper();
const { status: resetPassStatus, execute: executeForgotPass } = useApiWrapper();
const { status: signUpStatus, execute: executeSignup } = useApiWrapper();
const router = useRouter();
const authStore = useAuthStore();
const signInEmail = ref("");
const signInPass = ref("");
const isSignUpEmailMicrosoft = ref(false); // this is because ms emails are sent to spam at this moment
const forgotPassClicked = ref(false);
const showSignup = ref(false);
const emit = defineEmits();

export interface Props {
  showSignup?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showSignup: false,
});

const {t} = useI18n();
const modalTitle = computed(() => {
  if (showSignup.value) {
    return t("sign-up-form.sign-up");
  }
  if (forgotPassClicked.value) {
    return t("sign-in-form.forgot-pass");
  }

  return t("common.sign-in");
});

const handleLogin = async () => {
  await executeLogin(authStore.login(signInEmail.value, signInPass.value));

  if (signInStatus.value === "success") {

    if (authStore.user?.user_metadata?.account_type === "tutor") {
      router.push({ name: "Sessions" });
      return;
    }

    router.push({ name: "Tutors" });

  }
};

const handleResetPass = async (formData: { email: string }) => {
  await executeForgotPass(
    supabase.auth.resetPasswordForEmail(formData.email, {
      redirectTo: RESET_PASS_REDIRECT_URL,
    })
  );
};

const handleSignup = async (formData: {
  email: string;
  name: string;
  pass: string;
}) => {

  const MS_EMAIL_REGEX = /^\S+(outlook|hotmail|live)\S+$/;

  // if the email mathces ms emails, put notice to check spams
  if (formData.email.match(MS_EMAIL_REGEX)) {
    isSignUpEmailMicrosoft.value = true;
  }

  const metadata = {
    first_name: formData.name,
    account_type: "student",
    preferred_lang: i18n.global.locale.value
  };
  const email = formData.email;
  const password = formData.pass;

  await executeSignup(
    supabase.auth.signUp({
      email,
      password,
      options: {
        data: metadata,
      },
    })
  );

  event('conversion', {
        'send_to': 'AW-11419231839/FVQzCOWvwpUZEN_MjsUq',
        'value': 0.0,
        'currency': 'CAD'
    });
};

const showPassIconClick = (node, e) => {
  node.props.suffixIcon = node.props.suffixIcon === "eye" ? "eyeClosed" : "eye";
  node.props.type = node.props.type === "password" ? "text" : "password";
};

const closeModal = () => {
  emit("close");
};

onMounted(() => {
  showSignup.value = props.showSignup;
});
</script>

<template>
  <fwb-modal size="xl" @close="closeModal">
    <template #header>
      <h3 class="text-xl font-medium text-gray-900 dark:text-white">
        {{ modalTitle }}
      </h3>
    </template>
    <template #body>
      <!-- Login Modal content -->
      <div class="mx-auto max-w-md">
        <form class="space-y-6" @submit.prevent="handleLogin" v-if="!forgotPassClicked && !showSignup">
          <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ $t("common.email") }}</label>
            <input v-model="signInEmail" type="email" name="email" id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="email@teachtech.ca" required />
          </div>

          <div>
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{ $t("common.password") }}
            </label>
            <input v-model="signInPass" type="password" name="password" id="password" placeholder="••••••••"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required />
          </div>

          <div class="flex justify-between">
            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input id="remember" type="checkbox"
                  class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" />
              </div>
              <label for="remember" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                {{ $t("sign-in-form.remember-me") }}
              </label>
            </div>
           
            <a href="#" @click="forgotPassClicked = true;"
              class="text-sm text-blue-700 hover:underline dark:text-blue-500">{{ $t("sign-in-form.forgot-pass") }} ?</a>
          </div>

          <div class="flex flex-row gap-4 items-center">
            <fwb-spinner v-if="signInStatus === 'loading'" size="10" />
          <fwb-button v-else class="font-medium whitespace-nowrap" gradient="purple-blue">{{ $t('common.sign-in') }}</fwb-button>
          <p>
            <a @click="showSignup = true" href="#"
              class="text-sm font-medium text-gray-500 hover:text-blue-500 dark:text-gray-300">
              {{ $t("sign-in-form.no-account-signup") }}
            </a>
          </p>
          </div>
          
          <fwb-alert border closable type="danger" v-if="signInStatus === 'error'">
            {{ $t("sign-in-form.invalid-pass-or-email-error") }}
          </fwb-alert>
          
        </form>

        <template v-if="showSignup">
          <FormKit type="form" :actions="false" @submit="handleSignup">

            <FormKit type="email" autocomplete="email" name="email" :label="$t('common.email')" validation="required|email"
              placeholder="email@teachtech.ca" />

            <FormKit type="text" autocomplete="given-name" placeholder="John D" name="name" :label="$t('common.name')" validation="required|length:3|alpha_spaces:latin" />

            <FormKit type="password" autocomplete="current-password" placeholder="••••••••" suffix-icon="eyeClosed" @suffix-icon-click="showPassIconClick" name="pass"
            :label="$t('common.password')" validation="required|length:8" />

              <div class="flex flex-row gap-4 items-baseline">

            <template v-if="signUpStatus != 'loading'">
            <FormKit  type="submit">
              <fwb-button gradient="purple-blue" class="whitespace-nowrap font-medium">
                {{$t('sign-up-form.sign-up')}}
              </fwb-button>
            </FormKit>

            <p class="mt-4">
              <a @click="showSignup = false" href="#"
                class="text-sm font-medium text-gray-500 hover:text-blue-500 dark:text-gray-300">
                {{$t('sign-up-form.already-have-account')}}
              </a>
            </p>

          </template>
 
          </div>

            <fwb-spinner v-if="signUpStatus === 'loading'" size="10" />
            <fwb-alert border closable type="danger" v-if="signUpStatus === 'error'">{{$t('common.errors.try-again')}}</fwb-alert>
            <fwb-alert border type="info" v-if="signUpStatus === 'success'">
              {{$t('sign-up-form.signup-success-msg')}}
              <template v-if="isSignUpEmailMicrosoft">
                {{$t('sign-up-form.signup-success-verify-spam-msg')}}
              </template>
            </fwb-alert> 

            <div class="ml-3 mt-3 text-sm">
              <label for="terms" class="font-semi-light text-gray-500 dark:text-gray-300">
                {{$t('sign-up-form.accept-terms')}}
                <router-link :to="{ name: 'TermsAndConditions' }" @click="closeModal">
                  <a class="font-small text-gray-600 hover:underline dark:text-gray-500" href="#">
                    {{$t('common.terms-and-cond')}}
                  </a></router-link></label>
            </div>
            
          </FormKit>
        </template>

        <template v-if="forgotPassClicked">
          <FormKit type="form" :actions="false" @submit="handleResetPass">
            <FormKit type="email" name="email" :label="$t('common.email')"
              :help="$t('forgot-pass-form.email-will-be-sent-to-recover-account')" validation="required|email"
              placeholder="email@teachtech.ca" />

            <div class="flex flex-row gap-4 items-baseline">
              
                <FormKit v-if="resetPassStatus != 'loading'" type="submit">
                <fwb-button gradient="purple-blue" class="font-medium ">
                  {{ $t("sign-in-form.forgot-pass") }}
                </fwb-button>
              </FormKit>
              <fwb-spinner v-if="resetPassStatus === 'loading'" size="10" />
              
              <p>
                <a @click="forgotPassClicked = false;" href="#"
                  class="text-sm font-medium text-gray-500 hover:text-blue-500 dark:text-gray-300">
                  {{ $t("forgot-pass-form.return-to-connection") }}
                </a>
              </p>
            </div>
            
            <fwb-alert border closable type="danger" v-if="resetPassStatus === 'error'">
              {{$t('common.errors.try-again')}}
            </fwb-alert>
            <fwb-alert border type="success" v-if="resetPassStatus === 'success'">
              {{$t('forgot-pass-form.email-will-be-sent-if-you-have-account')}}
            </fwb-alert>
          </FormKit>
        </template>
      </div>
    </template>
  </fwb-modal>
</template>
